<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list height="80" class="mt-3 pa-5">
          <v-icon @click="drawer = false" class="" color="#1A347F"
            >mdi-close</v-icon
          >
          <a href="/">
            <img
              src="@/assets/logo_liff.png"
              width="95.75"
              height="28.22"
              class=""
              style="float: right"
            />
          </a>
        </v-list>
        <v-divider></v-divider>

        <!-- <v-list
          v-for="(i, index) in navbar"
          :key="index"
          class="fn22"
          style="color: #4f4f4f"
        >
          <v-list-item @click="goTo(i)" class="py-6">
            <v-divider class="mb-4"></v-divider>
            <v-list-item-title class="mx-4">{{
              i.category_name
            }}</v-list-item-title>
          </v-list-item>
        </v-list> -->
        <v-list
          v-for="item in Navigator"
          :key="item.key"
          class="fn22"
          style="color: #4f4f4f"
        >
          <v-list-group v-if="item.menuItem">
            <v-list-item slot="activator">
              <v-list-item-title>{{ item.headerName }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
            >
              <v-list-item-title
                ><div class="ma-0" style="color: #4f4f4f">
                  {{ menuList.text }}
                </div></v-list-item-title
              >
            </v-list-item>
          </v-list-group>
          <v-list-item v-else @click="ChangePage(item)" class="py-0">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
          <v-divider class="mt-4"></v-divider>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app elevation="0" color="white">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <!-- <v-img
          src="https://health-express-web.yuzudigital.com/img/logo_home.8a04b7b4.png"
        ></v-img> -->

        <v-spacer></v-spacer>
        <div class="text-right">
          <v-badge :content="messages" :value="messages" color="blue" overlap>
            <v-icon medium @click="goToNotification()"> mdi-bell </v-icon>
          </v-badge>
        </div>
      </v-app-bar>
      <v-main class="div_overflow">
        <div class="pa-4">
          <router-view></router-view>
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      rewardSelected: 1,
      messages: 0,
      drawer: false,
      Header: "",
      // picture: "",
      username: "",
      navbar: [],
      NavigatorDefault: [
        // {
        //   headerName: "Knowledge Hub",
        //   menuItem: [
        //     {
        //       key: "knowledge0",
        //       text: "NexGardFamily",
        //       path: "/ManageNexGardFamily",
        //       name: "ManageNexGardFamily",
        //     },
        //     {
        //       key: "knowledge1",
        //       text: "FRONTLINE",
        //       path: "/ManageFRONTLINE",
        //       name: "ManageFRONTLINE",
        //     },
        //     {
        //       key: "knowledge2",
        //       text: "Broadline",
        //       path: "/ManageBroadline",
        //       name: "ManageBroadline",
        //     },
        //     {
        //       key: "knowledge3",
        //       text: "Heartgard",
        //       path: "/ManageHeartgard",
        //       name: "ManageHeartgard",
        //     },
        //     {
        //       key: "knowledge4",
        //       text: "Knowledge Hub",
        //       path: "/ManageDAH",
        //       name: "ManageDAH",
        //     },
        //   ],
        // },
        {
          key: "KnowledgeHub",
          text: "KnowledgeHub",
          path: "/KnowledgeHub",
          name: "KnowledgeHub",
        },
        {
          key: "DigitalAssetsHub",
          text: "DigitalAssetsHub",
          path: "/DigitalAssetsHub",
          name: "DigitalAssetsHub",
        },

        {
          key: "menu1",
          text: "Games",
          path: "/ManageGame",
          name: "ManageGame",
        },
        {
          key: "menu2",
          text: "Rewards",
          path: "/ManageRewards",
          name: "ManageRewards",
        },
        {
          key: "menu3",
          text: "CE Points",
          path: "/ManagePoints",
          name: "ManagePoints",
        },
        {
          key: "menu4",
          text: "Your Profile",
          path: "/ManageProfile",
          name: "ManageProfile",
        },
        // {
        //   key: "menu6",
        //   text: "My Appointments",
        //   path: "/ManageAppointments",
        //   name: "ManageAppointments",
        // },
      ],
      NavigatorPoultry: [
        {
          key: "KnowledgeHub",
          text: "KnowledgeHub",
          path: "/KnowledgeHub",
          name: "KnowledgeHub",
        },
        // {
        //   key: "DigitalAssetsHub",
        //   text: "DigitalAssetsHub",
        //   path: "/DigitalAssetsHub",
        //   name: "DigitalAssetsHub",
        // },

        // {
        //   key: "menu1",
        //   text: "Games",
        //   path: "/ManageGame",
        //   name: "ManageGame",
        // },
        {
          key: "menu2",
          text: "Rewards",
          path: "/ManageRewards",
          name: "ManageRewards",
        },
        {
          key: "menu3",
          text: "CE Points",
          path: "/ManagePoints",
          name: "ManagePoints",
        },
        {
          key: "menu4",
          text: "Your Profile",
          path: "/ManageProfile",
          name: "ManageProfile",
        },
        {
          key: "menu6",
          text: "My Appointments",
          path: "/ManageAppointments",
          name: "ManageAppointments",
        },
      ],
      NavigatorLivestock: [
        {
          key: "KnowledgeHub",
          text: "KnowledgeHub",
          path: "/KnowledgeHub",
          name: "KnowledgeHub",
        },
        // {
        //   key: "DigitalAssetsHub",
        //   text: "DigitalAssetsHub",
        //   path: "/DigitalAssetsHub",
        //   name: "DigitalAssetsHub",
        // },

        {
          key: "menu1",
          text: "Games",
          path: "/ManageGame",
          name: "ManageGame",
        },
        {
          key: "menu2",
          text: "Rewards",
          path: "/ManageRewards",
          name: "ManageRewards",
        },
        {
          key: "menu3",
          text: "CE Points",
          path: "/ManagePoints",
          name: "ManagePoints",
        },
        {
          key: "menu10",
          text: "Online Consulting",
          path: "/ManageConsulting",
          name: "OnlineConsulting",
        },
        // เอาออกก่อน
        // {
        //   key: "menu6",
        //   text: "My Appointments",
        //   path: "/ManageAppointments",
        //   name: "ManageAppointments",
        // },
        {
          key: "menu4",
          text: "Your Profile",
          path: "/ManageProfile",
          name: "ManageProfile",
        },
      ],
      NavigatorPet: [
        {
          key: "KnowledgeHub",
          text: "KnowledgeHub",
          path: "/KnowledgeHub",
          name: "KnowledgeHub",
        },
        {
          key: "DigitalAssetsHub",
          text: "DigitalAssetsHub",
          path: "/DigitalAssetsHub",
          name: "DigitalAssetsHub",
        },

        {
          key: "menu1",
          text: "Games",
          path: "/ManageGame",
          name: "ManageGame",
        },
        // {
        //   key: "menu2",
        //   text: "Rewards",
        //   path: "/ManageRewards",
        //   name: "ManageRewards",
        // },
        {
          key: "menu3",
          text: "CE Points",
          path: "/ManagePoints",
          name: "ManagePoints",
        },
        // {
        //   key: "menu10",
        //   text: "Online Consulting",
        //   path: "/ManageConsulting",
        //   name: "OnlineConsulting",
        // },
        // {
        //   key: "menu6",
        //   text: "My Appointments",
        //   path: "/ManageAppointments",
        //   name: "ManageAppointments",
        // },
        {
          key: "menu4",
          text: "Your Profile",
          path: "/ManageProfile",
          name: "ManageProfile",
        },
      ],
      Navigator: [],
      userData: [],
      lineId: "",
    };
  },
  async created() {
    // this.getHeader();
    await this.getProfile();
    // await this.getUser();
    // this.Navigator = this.NavigatorDefault;

    // this.CheckHeader();
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      console.log("lineIdlineIdlineIdlineId", this.lineId);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      console.log("user Data", response.data.data);
      console.log("species_type", response.data.data.species_type);
      this.userData = response.data.data;
      if (this.userData.species_type == "ธุรกิจสัตว์ปีก") {
        this.Navigator = this.NavigatorPoultry;
      }
      if (
        this.userData.species_type ==
        "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป"
      ) {
        this.Navigator = this.NavigatorPet;
      }
      if (this.userData.species_type == "ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง") {
        this.Navigator = this.NavigatorLivestock;
      }
    },
    async getHeader() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledge_category_1/categoryTree`
      );
      console.log("knowledge_category_1", response.data);
      this.navbar = response.data.data;
      this.navbar[0].path = "/KnowledgeHub";
      this.navbar[1].path = "/DigitalAssetsHub";
    },
    goTo(val) {
      this.$router.push({ path: val.path }).catch(() => {});
    },
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      // console.log("เข้า ChangePage", val);
      // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      console.log("CheckHeader", this.$router.currentRoute.name);
      // this.Header = val;
      if (this.$router.currentRoute.name == "Index") {
        this.invisible = "Index";
      } else {
        this.invisible = "";
      }
    },
    Logout() {
      localStorage.removeItem("userBiData");
      window.location.reload();
    },
    goToNotification() {
      // this.$router.push({ path: "/Notification" }).catch(() => {});
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  /* background-color: rgb(235, 240, 248); */
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
