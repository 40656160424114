<template>
  <div class="mx-4" style="position: relative">
    <v-row style="z-index: 5; margin-top: -70px; position: absolute; right: 0">
      <!-- <img src="@/assets/logo_liff.png" width="95.75" height="28.22" /> -->
    </v-row>
    <v-row align="center" justify="center" class="text-center">
      <v-col cols="12">
        <v-avatar
          width="80px"
          height="80px"
          style="z-index: 5; margin-top: -80px"
        >
          <v-img src="@/assets/biah-member-club-logo.png" style="z-index: 1" />
          <!-- <v-img src="@/assets/profile.png" style="z-index: 1" /> -->
        </v-avatar>
      </v-col>
    </v-row>
    <v-card class="rounded-lg">
      <v-row
        align="end"
        class="text-center"
        style="background: #1a347f; height: 110px; margin-top: -75px"
      >
        <!-- <v-col cols="12">
          <v-card-text class="text-style white--text">
            {{ userData.species_type }}
          </v-card-text>
        </v-col> -->
        <v-col cols="12">
          <v-card-text class="text-style white--text">
            {{ userData.firstname }} {{ userData.lastname }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <!-- <v-card> -->
    <v-row
      class="rounded-lg"
      style="background: #06204b; height: 72px; margin-top: 12px"
    >
      <v-row style="height: 72px; position: relative" class="my-1">
        <v-col cols="1" class="ml-5" v-if="userData.type == 'ใช่'">
          <img
            src="@/assets/BI.png"
            align="left"
            justify="left"
            width="40"
            height="40"
            class="mx-2"
          />
        </v-col>
        <v-col
          cols="4"
          class="ml-5"
          @click="gotoManagePoints()"
          v-if="userData.type == 'ใช่'"
        >
          <div class="text-right font-weight-bold white--text fn22 mt-n1">
            <span v-if="userData.bi_current_point < 10000"
              >{{ userData.bi_current_point || 0 }}
            </span>
            <span v-if="userData.bi_current_point >= 10000"
              >{{ parseInt(userData.bi_current_point / 1000) }}k
            </span>
            <span class="font-weight-regular fn12">points</span>
            <div
              class="font-weight-regular"
              style="font-size: 10px; color: #9bbef2"
            >
              view points
            </div>
          </div>
        </v-col>
        <v-col cols="1" class="py-0 pr-0 ml-n3">
          <a-divider type="vertical" :dashed="true" style="height: 50px" />
        </v-col>
        <v-col cols="1" class="pl-0 ml-2">
          <img
            src="@/assets/icon/CE.png"
            align="left"
            justify="left"
            width="40"
            height="40"
          />
        </v-col>
        <v-col cols="4" class="pl-0 ml-n3" @click="gotoManagePoints()">
          <div class="text-right font-weight-bold white--text fn22 mt-n1">
            <span v-if="userData.ce_current_credit < 10000"
              >{{ userData.ce_current_credit }}
            </span>
            <span v-if="userData.ce_current_credit >= 10000"
              >{{ parseInt(userData.ce_current_credit / 1000) }}k
            </span>
            <span class="font-weight-regular fn12">points</span>
            <div
              class="font-weight-regular"
              style="font-size: 10px; color: #9bbef2"
            >
              view points
            </div>
          </div>
        </v-col>
      </v-row>
      <v-col cols="12" class="py-0">
        <v-divider class="mt-2"></v-divider>
      </v-col>
      <v-col cols="12" class="pa-0 mt-4" align="center" justify="center">
        <v-carousel
          cycle
          height="100%"
          show-arrows-on-hover
          hide-delimiter-background
          delimiter-icon="mdi-minus"
          class="rounded-lg"
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <!-- <a
              :href="
                slide.bannerUrl.includes('https://')
                  ? slide.bannerUrl
                  : `https://${slide.bannerUrl}`
              "
              target="_blank"
            > -->
            <v-img
              :src="slide.bannerImg"
              class="rounded-lg"
              style="object-fit: cover"
              @click="goTobannerUrl(slide.bannerUrl)"
            />

            <!-- </a> -->

            <!-- <div
              align="center"
              class="rounded-l-xl"
              style="
                background-color: #1a347f;
                opacity: 0.7;
                width: 105px;
                height: 30px;
                position: absolute;
                margin-top: -170px;
                right: 0;
              "
            >
              <span class="fn12-weight7 white--text" style="opacity: 1">
                {{ convertDate(slide.period_start) }} -
                {{ convertDateTo(slide.period_end) }}
              </span>
            </div> -->
          </v-carousel-item>
        </v-carousel>
      </v-col>

      <v-row align="center" justify="center" class="mt-5 rounded-0 text-center">
        <v-divider class="mt-2"></v-divider>
        <v-col cols="12" class="pb-0"> </v-col>
        <v-slide-group show-arrows center-active>
          <v-slide-item v-for="(item, i) in slideTop" :key="i">
            <v-col @click="goToTop(item.link)" align="center" justify="center">
              <div
                class="rounded-circle mt-4"
                style="background: #dfe2e9; width: 60px; height: 60px"
              >
                <img :src="item.image" class="my-2 mx-auto" />
              </div>
              <p class="fn12-weight7" style="color: #1a347f">{{ item.text }}</p>
            </v-col>
            <!-- <v-col @click="goToTopPoints()" align="center" justify="center">
              <div
                class="rounded-circle mt-4"
                style="background: #dfe2e9; width: 60px; height: 60px"
              >
                <img src="@/assets/icon/points.png" class="my-2 mx-auto" />
              </div>
              <p class="fn12-weight7" style="color: #1a347f">My points</p>
            </v-col>

            <v-col @click="goToMyRewards()" align="center" justify="center">
              <div
                class="rounded-circle mt-4"
                style="background: #dfe2e9; width: 60px; height: 60px"
              >
                <img src="@/assets/icon/rewards.png" class="my-2 mx-auto" />
              </div>
              <p class="fn12-weight7" style="color: #1a347f">My Rewards</p>
            </v-col>
            >
            <v-col @click="goToProfile()" align="center" justify="center">
              <div
                class="rounded-circle mt-4"
                style="background: #dfe2e9; width: 60px; height: 60px"
              >
                <img src="@/assets/icon/profile.png" class="my-3 mx-3" />
              </div>
              <p class="fn12-weight7" style="color: #1a347f">My Profile</p>
            </v-col> -->
          </v-slide-item>
        </v-slide-group>
        <v-col cols="12" class="pt-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-container>
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="mt-5 text-center"
        >
          <div style="color: #1a347f" class="fn22-weight7 mb-4">
            Explore Our Content
          </div>

          <v-tabs
            v-model="tab"
            icons-and-text
            centered
            active-class="active"
            color="#06204B"
            height="180"
            style="margin-left: -60px"
            class="mr-2"
          >
            <v-tab
              v-for="(item, index) in tabItems"
              :key="index"
              class="px-0"
              style="width: 200px"
              @click="selected(item.id)"
            >
              <div
                style="color: #1a347f"
                class="text-capitalize fn16-weight7 text-center mb-n6 mt-2"
              >
                {{ item.name }}
              </div>
              <img
                :src="item.bgImage"
                width="109"
                height="109"
                :id="`b${index}`"
              />
              <!-- :class="!index ? 'tab-color' : ''" -->
            </v-tab>
          </v-tabs>

          <!-- <v-tabs-items v-for="(item, index) in items" :key="index"> -->
          <v-tabs-items>
            <v-col cols="12" class="pt-0">
              <v-divider></v-divider>
            </v-col>
            <div v-if="itemIdisreal !== 0">
              <div align="center" v-for="(i, j) in tabItems" :key="j">
                <v-row v-if="i.id == itemIdisreal">
                  <v-col cols="9" class="text-left">
                    <span class="my-5 fn16">
                      {{ i.name }}
                    </span>
                  </v-col>
                  <v-col cols="3" v-if="itemIdisreal !== 1">
                    <p
                      class="my-5 text-right fn12-weight7"
                      style="color: #1a347f"
                      @click="goToDetailPage(i.id)"
                    >
                      View all
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="itemIdisreal == 0">
              <div v-for="(item, index) in items" :key="index">
                <v-row>
                  <v-col cols="9" class="text-left">
                    <span class="my-5 fn16">
                      {{ item.category_name }}
                    </span>
                  </v-col>
                  <v-col cols="3">
                    <p
                      class="my-5 text-right fn12-weight7"
                      style="color: #1a347f"
                      @click="goToDetailPage(0, item.id)"
                    >
                      View all
                    </p>
                  </v-col>
                </v-row>
                <!-- <h1 style="color: red">{{ item.category_name }}</h1> -->

                <v-slide-group v-model="slideGroup" center-active>
                  <v-slide-item
                    v-for="(object, i) in item.knowledgeList"
                    :key="i"
                  >
                    <v-card
                      class="mb-4 mr-4 rounded-xl"
                      max-width="250"
                      min-width="250"
                      min-height="325"
                      max-height="325"
                      v-show="idTab ? false : true"
                      @click="gotoDetail(object)"
                      v-if="object.status"
                    >
                      <v-img
                        :src="
                          object.knowledgeImg
                            ? object.knowledgeImg
                            : '@/assets/icon/Laptop.png'
                        "
                      />

                      <div
                        class="ml-4 mt-1 py-0 text-left fn11"
                        style="color: #6e798c; font-weight: 600"
                      >
                        <!-- {{object.knowledge_category_3.category_name}} -->
                      </div>

                      <div class="ml-4 text-left py-0">
                        <p
                          class="fn18-weight7"
                          style="
                            color: #081f32;
                            max-height: 3.5rem;
                            overflow: hidden;
                            display: block;
                            text-overflow: ellipsis;
                            font-weight: 500;
                            white-space: break-spaces;
                          "
                        >
                          {{ object.knowledge_name }}
                        </p>

                        <div
                          class="fn12"
                          style="
                            max-width: 250px;
                            max-height: 2.4rem;
                            overflow: hidden;
                            display: block;
                            text-overflow: ellipsis;
                            font-weight: 500;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                        >
                          {{
                            object.description
                              ? object.description
                              : "Our team was inspired by the seven skills of highly effective programmers created by the TechLead."
                                | limitText
                          }}
                        </div>
                      </div>
                      <v-row
                        style="
                          position: absolute;
                          bottom: 0;
                          width: 100%;
                          height: 70px;
                          left: 10px;
                        "
                      >
                        <v-card-actions class="pt-0 my-2">
                          <v-img
                            src="@/assets/BI.png"
                            max-width="32"
                            max-height="32"
                            class="mx-2"
                          />

                          <div
                            class="fn11"
                            style="color: #007ae9; font-weight: 600"
                          >
                            Earn {{ object.point }} Points
                          </div>
                        </v-card-actions>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>

                <!-- <v-row v-show="idTab ? false : true">
                sdsd
                <v-col class="text-right">
                  <v-btn
                    text
                    @click="previousSlide"
                    aria-label="Previous slide"
                  >
                    <a-icon type="left" />
                  </v-btn>

                  <v-btn
                    text
                    @click="nextSlide(object)"
                    aria-label="Next slide"
                  >
                    <a-icon type="right" />
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider class="my-2"></v-divider> -->
              </div>
            </div>
            <div v-if="itemIdisreal === 1">
              <div v-for="(item, index) in games" :key="index">
                <div
                  class="pa-0"
                  v-for="(object, index) in item.objects"
                  :key="index"
                >
                  <v-row
                    align="center"
                    v-for="(subject, index) in object.subjects"
                    :key="index"
                  >
                    <!-- <v-col cols="6" class="text-left">
                      <span class="my-4 fn16-weight7" style="color: #1a347f">
                        Play limits / Day
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <v-col
                        cols="1"
                        class="d-inline-block pa-0 ml-3"
                        v-for="(check, index) in subject.check"
                        :key="index"
                      >
                        <img :src="check.image" width="20" height="20" />
                      </v-col>
                      <v-col
                        cols="1"
                        class="d-inline-block pa-0 ml-3"
                        v-for="(check, index) in subject.noCheck"
                        :key="index"
                      >
                        <img :src="check.image" width="20" height="20" />
                      </v-col>
                    </v-col> -->
                    <v-col cols="12">
                      <!-- width="313"
                        max-width="370"
                        height="200"
                        max-height="200" -->
                      <v-img
                        class=""
                        max-width="370"
                        max-height="200"
                        :src="subject.image"
                        @click="onClickGame(subject.id)"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div v-if="itemIdisreal == 2">
              <v-slide-group v-model="slideGroup" center-active>
                <v-slide-item v-for="(reward, i) in rewards" :key="i">
                  <v-card
                    class="mb-4 mr-4 rounded-xl"
                    max-width="250"
                    min-width="250"
                    min-height="350"
                    max-height="350"
                    @click="goToRewards(reward)"
                    v-if="reward.status == 1"
                  >
                    <v-img class="object-cover mb-4" :src="reward.rewardImg" />
                    <v-col cols="12" class="text-left">
                      <span
                        class="pa-0 mx-2 mt-4 mb-12 text-left fn12-weight7"
                        style="color: #06204b; text-align: left"
                      >
                        {{ reward.reward_name }}
                      </span>
                    </v-col>
                    <v-card-actions class="pa-0 my-0">
                      <img
                        src="@/assets/BI.png"
                        height="30px"
                        width="30px"
                        align="left"
                        justify="left"
                        class="ml-2"
                      />
                      <div
                        class="ml-1 fn10"
                        style="color: #1a347f; font-weight: 600"
                      >
                        Use {{ reward.point }} Points to redeem
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
              <v-row justify="end">
                <v-col class="text-right">
                  <v-btn
                    text
                    @click="previousSlide"
                    aria-label="Previous slide"
                  >
                    <a-icon type="left" />
                  </v-btn>

                  <v-btn
                    text
                    @click="nextSlide(object)"
                    aria-label="Next slide"
                  >
                    <a-icon type="right" />
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-if="itemIdisreal == 3">
              <div v-for="(item, index) in itemsDigital" :key="index">
                <v-slide-group v-model="slideGroup" center-active>
                  <v-slide-item v-for="(object, i) in item.objects" :key="i">
                    <v-card
                      class="mb-4 mr-4 rounded-xl"
                      max-width="250"
                      min-width="250"
                      min-height="300"
                      max-height="300"
                      v-show="idTab ? false : true"
                      @click="gotoDigitalDetail(object)"
                      v-if="object.status"
                    >
                      <!-- min-height="358"
                    max-height="358" -->
                      <!-- :aspect-ratio="4 / 3" -->
                      <!-- :aspect-ratio="16 / 10" -->
                      <v-img
                        :src="
                          object.digitalImg
                            ? object.digitalImg
                            : '@/assets/icon/Laptop.png'
                        "
                      />

                      <div
                        class="ml-4 mt-1 py-0 text-left fn11"
                        style="color: #6e798c; font-weight: 600"
                      >
                        <!-- {{object.digital_category_3.category_name}} -->
                      </div>

                      <div class="ml-4 text-left py-0">
                        <span class="fn18-weight7" style="color: #081f32">
                          {{
                            object.digital_name
                              ? object.digital_name
                              : "" | limitHeader
                          }}
                        </span>

                        <div
                          class="fn12"
                          style="
                            max-width: 250px;
                            max-height: 2.4rem;
                            overflow: hidden;
                            display: block;
                            text-overflow: ellipsis;
                            font-weight: 500;
                            white-space: nowrap;
                            overflow: hidden;
                            ext-overflow: ellipsis;
                          "
                        >
                          {{
                            object.description
                              ? object.description
                              : "Our team was inspired by the seven skills of highly effective programmers created by the TechLead."
                                | limitText
                          }}
                        </div>
                      </div>
                      <v-row
                        style="
                          position: absolute;
                          bottom: 0;
                          width: 100%;
                          height: 70px;
                          left: 10px;
                        "
                      >
                        <!-- <v-card-actions class="pt-0 my-2">
                          <v-img
                            src="@/assets/BI.png"
                            max-width="32"
                            max-height="32"
                            class="mx-2"
                          />

                          <div
                            class="fn11"
                            style="color: #007ae9; font-weight: 600"
                          >
                            Earn {{ object.point }} Points
                          </div>
                        </v-card-actions> -->
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
            <div v-if="itemIdisreal == 4">
              <div v-for="(ceItem, index) in ceItems" :key="index">
                <div v-for="(object, index) in ceItem.objects" :key="index">
                  <div
                    class="px-0"
                    v-for="(subject, index) in object.subjects"
                    :key="index"
                  >
                    <v-card
                      class="mb-6 mt-4 rounded-xl"
                      max-width="374"
                      min-width="300"
                      max-height="151"
                      min-height="151"
                    >
                      <v-row class="pa-0">
                        <v-col cols="4" class="py-0 pr-0">
                          <v-img
                            class="object-cover rounded-l-xl"
                            style="background: #bcd1ff"
                            min-height="151"
                            min-width="128"
                            max-width="128"
                            max-height="151"
                            :src="
                              subject.image
                                ? subject.image
                                : '@/assets/icon/Laptop.png'
                            "
                          />
                        </v-col>
                        <v-col cols="8" class="pb-0 px-0">
                          <div class="mx-4 text-left pb-0">
                            <span class="fn14-weight7" style="color: #081f32"
                              >{{ subject.header | limitHeader }}
                            </span>
                          </div>
                          <div class="mx-6 text-left pb-0">
                            <span class="fn12" style="color: #081f32"
                              >{{ subject.title | limitText }}
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="9" class="text-right pa-0 ma-0 mt-n5">
                          <v-chip
                            color="#DFE2E9"
                            class="fn11 mx-4"
                            style="color: #6e798c"
                          >
                            <span
                              class="fn11"
                              style="color: #081f32; font-weight: 600"
                              >{{ subject.time }}
                            </span>
                          </v-chip>
                        </v-col>
                        <v-col cols="2" class="text-right pa-0 ma-0 mt-n5">
                          <div>
                            <span
                              class="fn10"
                              style="color: #1a347f; font-weight: 600"
                              >{{ subject.point }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="idTab === 6">
            </div> -->
          </v-tabs-items>
          <!-- จบตรงนี้ -->

          <!-- <v-tabs-items>
            <v-slide-group v-model="slideGroup" center-active>
              <v-slide-item v-for="(reward, i) in rewards" :key="i">
                <v-card
                  class="mb-4 mr-4 rounded-xl"
                  max-width="250"
                  min-width="250"
                  min-height="350"
                  max-height="350"
                  v-show="idTab == 2"
                  @click="goToRewards(reward)"
                  v-if="reward.status == 1"
                >
                  <v-img class="object-cover mb-4" :src="reward.rewardImg" />

                  <v-col cols="12" class="text-left">
                    <span
                      class="pa-0 mx-2 mt-4 mb-12 text-left fn12-weight7"
                      style="color: #06204b; text-align: left"
                    >
                      {{ reward.reward_name }}
                    </span>
                  </v-col>
                  <v-card-actions class="pa-0 my-0">
                    <img
                      src="@/assets/BI.png"
                      height="30px"
                      width="30px"
                      align="left"
                      justify="left"
                      class="ml-2"
                    />
                    <div
                      class="ml-1 fn10"
                      style="color: #1a347f; font-weight: 600"
                    >
                      Use {{ reward.point }} Points to redeem
                    </div>
                  </v-card-actions>
                </v-card>
              </v-slide-item>
            </v-slide-group>
            <v-row justify="end">
              <v-col class="text-right">
                <v-btn text @click="previousSlide" aria-label="Previous slide">
                  <a-icon type="left" />
                </v-btn>

                <v-btn text @click="nextSlide(object)" aria-label="Next slide">
                  <a-icon type="right" />
                </v-btn>
              </v-col>
            </v-row>
          </v-tabs-items> -->

          <!-- <div v-if="idTab === 2">
            <div v-for="(reward, index) in rewards" :key="index">
              <div >
                <div class="px-0">
                  <v-card
                  class="mx-auto inline-block overflow-x-auto rounded-lg mt-2"
                  max-width="100vw"
                  @click="goToRewards(reward)"
                >
                  <div
                    class="mx-auto"
                    align="center"
                    justify="center"
                    style="background: #1a347f"
                  >
                    <v-img
                      class="object-cover my-4"
                      :aspect-ratio="4 / 3"
                      :src="reward.rewardImg"
                    />
                  </div>

                  <v-card-subtitle
                    class="pa-0 mx-2 my-2 text-left fn12-weight7"
                    style="color: #06204b"
                  >
                    {{ reward.reward_name }}
                  </v-card-subtitle>
                  <v-card-actions class="pa-0 my-2">
                    <img
                      src="@/assets/BI.png"
                      height="15px"
                      width="15px"
                      align="left"
                      justify="left"
                      class="ml-2"
                    />
                    <div
                      class="ml-1 fn10"
                      style="color: #1a347f; font-weight: 600"
                    >
                      Use {{ reward.point }} Points to redeem
                    </div>
                  </v-card-actions>
                </v-card>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div v-if="idTab === 3">
            <div v-for="(item, index) in items" :key="index">
              <div v-for="(object, index) in item.objects" :key="index">
                <div class="px-0">
                  <v-card
                    class="mb-6 mt-4 rounded-xl"
                    max-width="374"
                    min-width="300"
                    max-height="128"
                    min-height="128"
                  >
                    <v-row>
                      <v-col cols="4" class="py-0 pr-0">
                        <img
                          class="object-cover rounded-l-xl"
                          style="background: #bcd1ff"
                          height="128"
                          width="128"
                          :src="
                            object.knowledgeImg
                              ? object.knowledgeImg
                              : '@/assets/icon/Laptop.png'
                          "
                        />
                      </v-col>
                      <v-col cols="8" class="pb-0 px-0">
                        <div class="mx-4 text-left pb-0">
                          <span class="fn20-weight7" style="color: #081f32"
                            >{{ object.knowledge_name | limitHeader }}
                          </span>
                        </div>
                        <div class="mr-4 mt-4 text-right">
                          <span class="fn11" style="color: #081f32"
                            >{{ convertDateDAH(object.createdAt) }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </div>
            </div>
          </div> -->
        </v-row>
      </v-container>
      <v-row>
        <v-footer
          width="100%"
          padless
          class="pa-2 ma-0 text-center"
          style="
            background: #f6f6f6;
            color: #1a347f;
            font-weight: 600;
            font-size: 10px;
          "
        >
          <v-col class="text-center pa-0" cols="12">
            <div class="ma-0 text-center">
              © 2010-2021 Boehringer Ingelheim International GmbH. All rights
              reserved.
            </div>
          </v-col>
        </v-footer>
      </v-row>
    </v-row>
    <!-- </v-card> -->
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  filters: {
    limitText(val) {
      return val.length > 80 ? val.slice(0, 70) + "..." : val;
    },
    limitHeader(val) {
      return val.length > 28 ? val.slice(0, 28) + "..." : val;
    },
  },
  components: {},
  data() {
    return {
      predata: [],
      slideTopPet: [
        {
          text: "My Points",
          image: require("@/assets/icon/points.png"),
          link: "/ManagePoints",
        },
        {
          text: "My Rewards",
          image: require("@/assets/icon/rewards.png"),
          link: "/ManageMyRewards",
        },
        {
          text: "My Profile",
          image: require("@/assets/icon/profile.png"),
          link: "/ManageProfile",
        },
      ],
      slideTopDefault: [],
      slideTop: [
        {
          text: "My Points",
          image: require("@/assets/icon/points.png"),
          link: "/ManagePoints",
        },
        {
          text: "My Rewards",
          image: require("@/assets/icon/rewards.png"),
          link: "/ManageMyRewards",
        },
        {
          text: "My Profile",
          image: require("@/assets/icon/profile.png"),
          link: "/ManageProfile",
        },
        {
          text: "Appointments",
          image: require("@/assets/icon/appointment.png"),
          link: "/ManageAppointments",
        },
      ],
      lineId: "",
      page: 1,
      model: null,
      slideGroup: 0,
      tab: 0,
      indexSelected: 0,
      currentDate: moment().format("YYYY-MM-DD"),
      items: [
        {
          name: "Knowledge Hub",
          bgImage: require("@/assets/icon/icons/KH.png"),
          objects: [],
        },
      ],
      itemsDigital: [
        {
          name: "Digital Assets Hub",
          bgImage: require("@/assets/icon/icons/DAH.png"),
          objects: [],
        },
      ],
      gamesDefault: [
        {
          name: "Games",
          bgImage: require("@/assets/icon/icons/game.png"),
          header: "Digital Marketing Knowledge",
          objects: [
            {
              name: "Games",
              nameTab: "Games",
              subjects: [
                {
                  image: require("@/assets/game_pig.png"),
                  check: [
                    { image: require("@/assets/icon/check.png") },
                    { image: require("@/assets/icon/check.png") },
                    { image: require("@/assets/icon/check.png") },
                  ],
                  noCheck: [
                    { image: require("@/assets/icon/check-white.png") },
                    { image: require("@/assets/icon/check-white.png") },
                  ],
                },
              ],
            },
          ],
        },
      ],
      gamesPet: [
        {
          name: "Games",
          bgImage: require("@/assets/icon/icons/game.png"),
          header: "Digital Marketing Knowledge",
          objects: [
            {
              name: "Games",
              nameTab: "Games",
              subjects: [
                {
                  image: require("@/assets/game_pet.png"),
                  check: [
                    { image: require("@/assets/icon/check.png") },
                    { image: require("@/assets/icon/check.png") },
                    { image: require("@/assets/icon/check.png") },
                  ],
                  noCheck: [
                    { image: require("@/assets/icon/check-white.png") },
                    { image: require("@/assets/icon/check-white.png") },
                  ],
                },//เปิดเกมเพท
              ],
            },
          ],
        },
      ],
      ceItems: [
        {
          name: "CE Credit",
          bgImage: require("@/assets/icon/icons/CE.png"),
          objects: [
            {
              name: "Lastest Content",
              nameTab: "CE",
              subjects: [
                {
                  image: require("@/assets/CE/pic3.jpg"),
                  time: "9 Days Left",
                  header:
                    "Feline Atopic Syndrome (FAS) : อาการและแนวทางการวินิจฉัย",
                  title:
                    "รู้จักแนวทางการวินิจฉัยและอาการเบื้องต้นของโรค FASโรคกลุ่มภูมิแพ้ในแมวที่แตกต่างจากในสุนัข",
                  point: "3 Points",
                },
                {
                  image: require("@/assets/CE/pic4.jpg"),
                  time: "40 Days Left",
                  header: "Feline Atopic Syndrome (FAS) : อัพเดตแนวทางการรักษา",
                  title:
                    "มาเรียนรู้แนวทางการรักษา FAS ฉบับอัพเดตตามข้อมูลปี ค.ศ. 2021",
                  point: "3 Points",
                },
              ],
            },
          ],
        },
      ],
      rewards: [],
      slides: [
        // require("@/assets/header.png"),
        // require("@/assets/header.png"),
        // require("@/assets/header.png"),
        // require("@/assets/header.png"),
      ],

      idTab: 0,
      // วาป
      tabItems: [],

      // Poultry
      // Livestock
      // Pet
      // ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป
      // ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง
      // ธุรกิจสัตว์ปีก
      tabItemsPoultry: [
        {
          id: 0,
          name: "Knowledge Hub",
          bgImage: require("@/assets/icon/icons/KH.png"),
          header: "Knowledge Hub",
          header2: "Digital Marketing Knowledge",
          nameTab: "Knowledge Hub",
        },
        // {
        //   id: 1,
        //   name: "Games",
        //   bgImage: require("@/assets/icon/icons/game.png"),
        //   header: "Games",
        //   nameTab: "Games",
        // },
        {
          id: 2,
          name: "Rewards",
          bgImage: require("@/assets/icon/icons/reward.png"),
          header: "Rewards",
          nameTab: "Rewards",
        },
        // {
        //   id: 3,
        //   name: "Digital Asset Hubs",
        //   bgImage: require("@/assets/icon/icons/DAH.png"),
        //   header: "Lastest Content",
        //   nameTab: "DAH",
        // },
        // {
        //   id: 4,
        //   name: "CE Credit",
        //   bgImage: require("@/assets/icon/icons/CE.png"),
        //   header: "Lastest Content",
        //   nameTab: "CE",
        // },
      ],
      tabItemsPet: [
        {
          id: 0,
          name: "Knowledge Hub",
          bgImage: require("@/assets/icon/icons/KH.png"),
          header: "Knowledge Hub",
          header2: "Digital Marketing Knowledge",
          nameTab: "Knowledge Hub",
        },
        {
          id: 1,
          name: "Games",
          bgImage: require("@/assets/icon/icons/game.png"),
          header: "Games",
          nameTab: "Games",
        },
        // {
        //   id: 2,
        //   name: "Rewards",
        //   bgImage: require("@/assets/icon/icons/reward.png"),
        //   header: "Rewards",
        //   nameTab: "Rewards",
        // },
        {
          id: 3,
          name: "Digital Asset Hubs",
          bgImage: require("@/assets/icon/icons/DAH.png"),
          header: "Lastest Content",
          nameTab: "DAH",
        },
        // {
        //   id: 4,
        //   name: "CE Credit",
        //   bgImage: require("@/assets/icon/icons/CE.png"),
        //   header: "Lastest Content",
        //   nameTab: "CE",
        // },
      ],
      tabItemsLivestock: [
        {
          id: 0,
          name: "Knowledge Hub",
          bgImage: require("@/assets/icon/icons/KH.png"),
          header: "Knowledge Hub",
          header2: "Digital Marketing Knowledge",
          nameTab: "Knowledge Hub",
        },
        {
          id: 1,
          name: "Games",
          bgImage: require("@/assets/icon/icons/game.png"),
          header: "Games",
          nameTab: "Games",
        },
        {
          id: 2,
          name: "Rewards",
          bgImage: require("@/assets/icon/icons/reward.png"),
          header: "Rewards",
          nameTab: "Rewards",
        },
        // {
        //   id: 3,
        //   name: "Digital Asset Hubs",
        //   bgImage: require("@/assets/icon/icons/DAH.png"),
        //   header: "Lastest Content",
        //   nameTab: "DAH",
        // },
        // {
        //   id: 4,
        //   name: "CE Credit",
        //   bgImage: require("@/assets/icon/icons/CE.png"),
        //   header: "Lastest Content",
        //   nameTab: "CE",
        // },
        {
          id: 6,
          name: "Online Consulting",
          bgImage: require("@/assets/icon/icons/online-consulting.png"),
          header: "Online Consulting",
          nameTab: "OC",
        },
      ],
      userData: [],

      itemIdisreal: 0,
    };
  },

  async mounted() {
    // this.$liff.login();
    await this.getProfile();
    // await this.getUser();
    // species_type
    // Poultry
    // Livestock
    // Pet
    // ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป
    // ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง
    // ธุรกิจสัตว์ปีก
  },
  watch: {
    // tab(val) {
    //   console.log(val);
    // },
  },
  methods: {
    goTobannerUrl(val) {
      if (typeof val == "number") {
        this.$router
          .push({ path: `ManageQuestionnaire?id=${val}` })
          .catch(() => {});
      } else {
        window.open(val);
      }
    },
    goToTop(val) {
      this.$router.push(val);
    },
    onClickGame(val) {
      if (typeof val == "number") {
        this.$router.push({ path: `ManageQuiz?id=${val}` }).catch(() => {});
      } else if (
        this.userData.species_type == "ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง"
      ) {
        window.open(
          `https://main.d3uffisbouvxtp.amplifyapp.com/?userId=${this.lineId}` // เกมหมู
        );
      } else if (
        this.userData.species_type ==
        "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป"
      ) {
        window.open(
          `https://main.d3ftaj8qjj2ucp.amplifyapp.com/?userId=${this.lineId}` // เกมหมา

        );
      }
    },
    gotoManagePoints() {
      this.$router.push("ManagePoints");
    },
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      console.log("response error", response);

      // response.data.message: "Cannot read properties of null (reading 'dataValues')"
      // response.data.response_status: "ERROR"
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);

      this.userData = response.data.data;
      if (this.userData.species_type == "ธุรกิจสัตว์ปีก") {
        this.tabItems = this.tabItemsPoultry;
        // this.slideTop = this.slideTopPoultry;
        this.games = this.gamesPoultry; //ยังไม่มีเกม
      }
      if (
        this.userData.species_type ==
        "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป"
      ) {
        this.tabItems = this.tabItemsPet;
        this.slideTop = this.slideTopPet;
        this.games = this.gamesPet;
        await this.getAllQuiz();
        //เกมหมา
      }
      if (this.userData.species_type == "ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง") {
        this.tabItems = this.tabItemsLivestock;
        // this.slideTop = this.slideTopDefault;
        this.games = this.gamesDefault;
        // เกมหมู
        
      }
      await this.getAllBanner();
      await this.getAllKnowledge();
      await this.getAllDigital();
      await this.getAllRewards();
      await this.getAllQuestionnaire(); //เอา questionaire ใส่ให้ ทุกคน เอา quiz ใส่ให้ pet
      localStorage.setItem("userBiData", Encode.encode(this.userData));
    },

    gotoDetail(item) {
      // console.log("item", item);

      this.$router.push({
        path: "ELearningFRONTLINE",
        query: {
          reward: item.id,
        },
      });
    },
    gotoDigitalDetail(item) {
      console.log("item", item);

      this.$router.push({
        path: "ELearningFRONTLINEDigital",
        query: {
          reward: item.id,
        },
      });
    },
    formatDate(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    convertDate(val) {
      return moment(val).format("DD");
    },
    convertDateTo(val) {
      return moment(val).format("DD MMM YYYY");
    },
    convertDateReward(val) {
      if (val) return moment(val).format("DD MMM YYYY");

      return moment().format("DD MMM YYYY");
    },
    convertDateDAH(val) {
      return moment(val).startOf("day").fromNow();
    },
    async getAllQuestionnaire() {
      // const response = await this.axios.get(`http://localhost:8081/quizs`);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/questionaireNew`
      );
      // slides;
      // bannerImg;
      // bannerUrl;
      const predata = response.data.data.filter(
        (element) => element.upload_image
      );
      console.log("preslide", this.slides);
      // console.log("predata", predata);
      predata.forEach((element) => {
        this.slides.push({
          bannerImg: element.upload_image,
          bannerUrl: element.id,
        });
      });
      //เข้า banner
      // this.predata.forEach((element) => {
      //   this.games[0].objects[0].subjects.push({
      //     image: element.upload_image,
      //     id: element.id,
      //   });
      // });
      console.log("postslide", this.slides);
    },
    async getAllQuiz() {
      // const response = await this.axios.get(`http://localhost:8081/quizs`);
      const response = await this.axios.get(`${process.env.VUE_APP_API}/quizs`);
      // this.items = response.data.data;
      // this.slides.push(response.data.data);
      this.predata = response.data.data.filter(
        (element) => element.upload_image
      );
      console.log("predata", this.predata);
      console.log("games", this.games[0].objects[0].subjects);
      // this.predata.forEach((element) => {
      //   this.slides.push({
      //     bannerImg: element.upload_image,
      //     bannerUrl: element.id,
      //   });
      // });
      //เข้า banner
      this.predata.forEach((element) => {
        this.games[0].objects[0].subjects.push({
          image: element.upload_image,
          id: element.id,
        });
      });
      console.log("quiz", this.games);
    },
    async getAllBanner() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/banner?usertype=${this.userData.species_type}`
      );
      const slides = response.data.data;

      this.slides = slides.filter((element) => element.status == true);

      // if (
      //   this.userData.species_type ==
      //   "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป"
      // ) {
      //   await this.getAllQuestionnaire();
      // }

      console.log("this.slides", this.slides);
    },
    // getAllKnowledge
    async getAllKnowledge() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledge_category_1/categoryMainPage?usertype=${this.userData.species_type}`
      );
      console.log("items knowledge1111", response.data.data);
      this.items = response.data.data;
      // this.items[0].objects = response.data.data;

      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      // console.log("items all knowledge", this.items);
    },
    // async getAllKnowledge() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/knowledge?usertype=${this.userData.species_type}`
    //   );
    //   this.items[0].objects = response.data.data;

    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //   }
    //   console.log("items all knowledge", this.items);
    // },
    async getAllDigital() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital?usertype=${this.userData.species_type}`
      );
      this.itemsDigital[0].objects = response.data.data;

      for (let i in this.itemsDigital) {
        this.itemsDigital[i].count = parseInt(i) + 1;
      }
      console.log("itemsDigital all itemsDigital", this.itemsDigital);
      // knowledgeCategory1Id
    },
    async getAllRewards() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reward?usertype=${this.userData.species_type}
          `
      );
      console.log("getAllRewards", response.data);
      this.rewards = response.data.data;
      // for ( let i in response.data.data) {
      //   this.items[i].reward_name = response.data.data[i].reward_name;

      // }
    },
    nextSlide(item) {
      this.slideGroup++;
      this.slideGroup === item.length ? (this.slideGroup = 0) : "";
    },
    previousSlide() {
      this.slideGroup === 0 ? (this.slideGroup = 0) : this.slideGroup--;
    },
    selected(index) {
      this.itemIdisreal = index;
      // console.log('this.itemIdisreal',this.itemIdisreal);
      if (index == 6) {
        this.$router.push("/ManageConsulting");
      }
      // const addTab = `b${index}`;
      // const removeTab = `b${this.indexSelected}`;
      // this.indexSelected = index;

      // document.getElementById(removeTab).classList.remove("tab-color");
      // document.getElementById(addTab).classList.add("tab-color");

      // this.idTab = index;
    },
    goToPoints() {
      this.$router.push("/ManagePoints");
    },
    goToProfile() {
      this.$router.push("/ManageProfile");
    },
    goToMyRewards() {
      // RewardsPage?reward=47
      this.$router.push(`/ManageMyRewards`);
    },
    goToRewards(aa) {
      console.log("aaa", aa);
      // RewardsPage?reward=47
      this.$router.push(`/RewardsPage?reward=${aa.id}`);
    },
    goToDetailPage(id, index) {
      console.log("id", id);
      if (id === 0) {
        this.$router.push(`/KnowledgeHub?id=${index}`);
      }
      if (id === 1) {
        this.$router.push("/ManageGame");
      }
      if (id === 2) {
        this.$router.push("/ManageRewards");
      }
      if (id === 3) {
        this.$router.push("/DigitalAssetsHub");
      }
      if (id === 4) {
        this.$router.push("/ManageCE");
      }

      ///////////วาป
      // if (name === "Digital Marketing Knowledge ") {
      //   this.$router.push("/KnowledgeHub?id=12");
      // }
      // if (name === "aaaaaaa") {
      //   this.$router.push("/KnowledgeHub?id=13");
      // }
    },
    openWindow() {
      this.$liff.openWindow({
        url: "https://developers.line.me/en/docs/liff/overview/",
      });
    },
    closeWindow() {
      this.$liff.closeWindow();
    },
    sendMessage() {
      this.$liff
        .sendMessages([
          {
            type: "text",
            text: "You/'ve successfully sent a message! Hooray!",
          },
          {
            type: "sticker",
            packageId: "2",
            stickerId: "144",
          },
        ])
        .then(function () {
          window.alert("Message sent");
        })
        .catch(function (error) {
          window.alert("Error sending message: " + error);
        });
    },

    closeWindowLiff() {
      this.$liff.closeWindow();
    },
    goToLink(url) {
      this.$router.push(`/${url}`);
    },
  },
};
</script>

<style scoped>
.text-style {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}
.active {
  color: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.tab-color {
  border: 5px solid #9f9f9f;
  border-radius: 55px;
}
</style>
